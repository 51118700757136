import Firebase from './Firebase';
import { collection, getDocs } from "firebase/firestore";

/**
 * Get from Firebase
 * @param location
 */
const getFromFirebase = async (location) => {
  const querySnapshot = await getDocs(collection(Firebase, location));
  const docs = [];
  querySnapshot.forEach((doc) => {
    docs.push(doc.data());
  });
  return docs;
};

export default getFromFirebase;