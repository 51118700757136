import Calendar from "react-calendar";

const Cal = () => {

  return (
    <div className="fade pcar-calendar">
      <div className="calendar-left">
        <Calendar tileContent={({ date, view }) => view === 'month' && date.getDay() === 2 ? <p className="event green">Weekly Meeting</p> : view === 'month' && date.getDay() === 5 ? <p className="event yellow"><span>Event</span><img src={'https://www.pcarmarket.com/static/media/uploads/galleries/photos/uploads/galleries/v-0017001-1972-porsche-911-iroc-rsr-hsr-racer-blue/.thumbnails/a-002.jpg/a-002-tiny-600x400-0.5x0.jpg'} /></p> : null} />
      </div>
      <div className="calendar-right">
        <h2>Tue, November 23rd, 2021 (EST)</h2>
        <div className="calendar-grid">
          <div className="calendar-line"><p>12 am</p></div>
          <div className="calendar-line"><p>1 am</p></div>
          <div className="calendar-line"><p>2 am</p></div>
          <div className="calendar-line"><p>3 am</p></div>
          <div className="calendar-line"><p>4 am</p></div>
          <div className="calendar-line"><p>5 am</p></div>
          <div className="calendar-line"><p>6 am</p></div>
          <div className="calendar-line"><p>7 am</p></div>
          <div className="calendar-line"><p>8 am</p></div>
          <div className="calendar-line"><p>9 am</p></div>
          <div className="calendar-line"><p>10 am</p></div>
          <div className="calendar-line"><p>11 am</p></div>
          <div className="calendar-line"><p>12 pm</p></div>
          <div className="calendar-line"><p>1 pm</p></div>
          <div className="calendar-line"><p>2 pm</p></div>
          <div className="calendar-line"><p>3 pm</p></div>
          <div className="calendar-line"><p>4 pm</p></div>
          <div className="calendar-line"><p>5 pm</p></div>
          <div className="calendar-line"><p>6 pm</p></div>
          <div className="calendar-line">
            <p>7 pm</p>
            <div className="line-event green">Weekly Meeting</div>
          </div>
          <div className="calendar-line"><p>8 pm</p></div>
          <div className="calendar-line"><p>9 pm</p></div>
          <div className="calendar-line"><p>10 pm</p></div>
          <div className="calendar-line"><p>11 pm</p></div>
        </div>
      </div>
    </div>
  )
}

export default Cal;