import '../styles/Dashboard.scss';
import { Bar, Line } from "react-chartjs-2";
import { useEffect, useState } from "react";
import Users from "../users/Users";
import User from "../user/User";
import setCookie from "../components/SetCookie";
import getFromFirebase from "../components/getFromFirebase";
import NewCar from "../new-car/NewCar";
import NewItem from "../new-item/NewItem";
import Prospects from "../prospects/Prospects";
import Login from "../login/Login";
import Calendar from "../calendar/Calendar";

const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: 'Car Types (October)',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        '#C3C3C3',
        '#C3C3C3',
        '#C3C3C3',
        '#C3C3C3',
        '#C3C3C3',
        '#C3C3C3',
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const data2 = {
  labels: ['Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7'],
  datasets: [
    {
      label: 'Started Auctions',
      data: [8, 12, 7, 11, 10, 8, 9],
      fill: false,
      backgroundColor: '#C3C3C3',
      borderColor: '#C3C3C3',
    },
    {
      label: 'Completed Auctions',
      data: [3, 5, 4, 3, 2, 4, 3],
      fill: false,
      backgroundColor: '#7cd982',
      borderColor: '#7cd982',
    },
    {
      label: 'Failed Auctions',
      data: [2, 1, 3, 0, 1, 0, 2],
      fill: false,
      backgroundColor: '#d97575',
      borderColor: '#d97575',
    },
  ],
};

const options2 = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const Dashboard = () => {
  const [customers, setCustomers] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [user, setUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [sources, setSources] = useState(false);
  const [newSource, setNewSource] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [unqualified, setUnqualified] = useState(false);
  const [qualified, setQualified] = useState(false);
  const [dealerQualified, setDealerQualified] = useState(false);
  const [converted, setConverted] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successfulConvert, setSuccessfulConvert] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [permissionLevel, setPermissionLevel] = useState(1);
  const [prospects, setProspects] = useState([]);
  const [currentProspect, setCurrentProspect] = useState(null);
  const [all, setAll] = useState(true);
  const [admin, setAdmin] = useState('admin@pcarmarket.com');

  const dataTemplate = {
    sales_agent: '',
    title: '',
    sourcer: '',
    primary_contact: '',
    has_title: '',
    title_received: '',
    card_permission: '',
    card_digits: '',
    link: '',
    notes: '',
    temperature: 'warm',
    make: '',
    model: '',
    exterior_color: '',
    interior_color: '',
    options: '',
    type: '',
    mileage: '',
    vin: '',
    year: '',
    country: '',
    postal_code: '',
    phone_number: '',
    odometer_value: '',
    odometer_units: '',
    reserve_auction: '',
    reserve_price: '',
    contact_logs: [],
    images: [],
    category: 'Car',
  }

  const [prospectData, setProspectData] = useState(dataTemplate);

  useEffect(() => {
    if (prospects.length) {
      if (currentProspect) {
        /**
         * We're editing a prospect
         */
        prospects.forEach((p) => {
          if (p.id === currentProspect) {
            setProspectData({
              id: p.id,
              timestamp: p.timestamp,
              sales_agent: p.sales_agent,
              title: p.title,
              sourcer: p.sourcer,
              primary_contact: p.primary_contact,
              has_title: p.has_title,
              title_received: p.title_received,
              card_permission: p.card_permission,
              card_digits: p.card_digits,
              link: p.link,
              notes: p.notes,
              temperature: p.temperature,
              make: p.make,
              model: p.model,
              exterior_color: p.exterior_color,
              interior_color: p.interior_color,
              options: p.options,
              type: p.type,
              mileage: p.mileage,
              vin: p.vin,
              year: p.year,
              country: p.country,
              postal_code: p.postal_code,
              phone_number: p.phone_number,
              odometer_value: p.odometer_value,
              odometer_units: p.odometer_units,
              reserve_auction: p.reserve_auction,
              reserve_price: p.reserve_price,
              contact_logs: p.contact_logs,
              images: p.images,
              category: p.category,
            })
          }
        })
      } else {
        /**
         * We're not editing a prospect, reset to default
         */
        setProspectData(dataTemplate);
      }
    }
  }, [prospects, currentProspect]);

  const loadProspects = async () => {
    const p = await getFromFirebase("prospects");
    return p;
  }

  /**
   * Set token cookie if URL is recognized
   */
  useEffect(() => {
    /**
     * Get token from URL
     * @type {URLSearchParams}
     */
    let params = (new URL(document.location)).searchParams;
    let token = params.get('token');
    if (token) {
      setCookie('token', token);
      /**
       * TODO: Log them in
       */
    }
    /**
     * Get Prospect data
     */
    loadProspects().then((p) => setProspects(p));
  }, []);

  if (!loggedIn) {
    return (
      <Login forgotPassword={forgotPassword} setForgotPassword={setForgotPassword} setLoggedIn={setLoggedIn} setAdmin={setAdmin} />
    )
  }

  return (
    <div className="dashboard">
      <div className="sidebar">
        <div className="sidebar-section">
          <div className="block sidebar-item profile logo">
            <img src="https://www.pcarmarket.com/static/img/favicon.png" alt="PCARMARKET" />
          </div>
          <div className={`block sidebar-item ooo ${!customers && !user && !calendar && !newSource && !newItem && !sources && !successfulConvert ? 'false' : 'true'}`} onClick={
            () => {
              setNewSource(false);
              setNewItem(false);
              setSources(false);
              setCustomers(false);
              setUser(false);
              setCalendar(false);
              setCurrentProspect(null);
              setSuccessfulConvert(false);
            }
          }>
            <i className="fas fa-chart-line"></i>
            <p>Overview</p>
          </div>
          <div className={`block sidebar-item ccc ${customers}`} onClick={
            () => {
              setNewSource(false);
              setNewItem(false);
              setSources(false);
              setCustomers(true);
              setUser(false);
              setCalendar(false);
              setCurrentProspect(null);
              setSuccessfulConvert(false);
            }
          }>
            <i className="fas fa-street-view"></i>
            <p>Users</p>
          </div>
          <div className={`block sidebar-item sns ${(newSource || newItem) && currentProspect === null} disabled`} onClick={
            () => {
              setNewSource(true);
              setNewItem(false);
              setSources(false);
              setCustomers(false);
              setUser(false);
              setCalendar(false);
              setCurrentProspect(null);
              setProspectData(dataTemplate);
              setSuccessfulConvert(false);
            }
          }>
            <i className="fas fa-car"></i>
            <i className="fas fa-plus sqr"></i>
            <p>New Prospect</p>
          </div>
          <div className={`block sidebar-item sss ${sources || currentProspect !== null} disabled`} onClick={
            () => {
              setNewSource(false);
              setNewItem(false);
              setSources(true);
              setCustomers(false);
              setUser(false);
              setCalendar(false);
              setSuccessfulConvert(false);
            }
          }>
            <i className="fas fa-book"></i>
            <p>Prospect List</p>
          </div>
          <div className={`block sidebar-item bbb ${calendar} disabled`} onClick={
            () => {
              // setNewSource(false);
              // setNewItem(false);
              // setSources(false);
              // setCustomers(false);
              // setUser(false);
              // setCalendar(true);
              // setCurrentProspect(null);
              // setSuccessfulConvert(false);
            }
          }>
            <i className="fas fa-calendar-alt"></i>
            <p>Calendar</p>
          </div>
          {/*
          <div className="block sidebar-item">
            <i className="fas fa-file-download"></i>
            <p>Reports</p>
          </div>
          */}
        </div>
        <div className="sidebar-section">
          <div className="block sidebar-item profile" onClick={() => {
            setLoggedIn(false);

          }}>
            <i className="fas fa-sign-out-alt"></i>
            <p>Sign Out</p>
          </div>
          <div className="block sidebar-item profile user-profile">
            <img src="/admin.jpg" alt={admin} />
            <div className="user-profile-info">
              <p>{admin}</p>
              <p className="permission-level">Admin</p>
            </div>
          </div>
        </div>
      </div>
      <div className="main">
        <div className="content">
          { user && <User setUser={setUser} currentUser={currentUser} /> }
          { newSource && <NewCar setSuccessfulConvert={setSuccessfulConvert} prospects={prospects} setNewItem={setNewItem} newSource={newSource} newItem={newItem} currentProspect={currentProspect} setCurrentProspect={setCurrentProspect} prospectData={prospectData} setProspectData={setProspectData} setUser={setUser} setNewSource={setNewSource} setSources={setSources} /> }
          { newItem && <NewItem setSuccessfulConvert={setSuccessfulConvert} prospects={prospects} setNewItem={setNewItem} newSource={newSource} newItem={newItem} currentProspect={currentProspect} setCurrentProspect={setCurrentProspect} prospectData={prospectData} setProspectData={setProspectData} setUser={setUser} setNewSource={setNewSource} setSources={setSources} /> }
          {
            successfulConvert && (
              <div className="fade">
                <p>Prospect successfully converted to Asana task. View <a className="converted-a" href="https://app.asana.com/0/1203323119571099/list" target="_blank">here</a>.</p>
              </div>
            )
          }
          {
            sources && (
              <Prospects
                unqualified={unqualified}
                qualified={qualified}
                dealerQualified={dealerQualified}
                converted={converted}
                failed={failed}
                setUnqualified={setUnqualified}
                setQualified={setQualified}
                setDealerQualified={setDealerQualified}
                setConverted={setConverted}
                setFailed={setFailed}
                setNewSource={setNewSource}
                sources={sources}
                setSources={setSources}
                prospects={prospects}
                search={search}
                searchVal={searchVal}
                setProspects={setProspects}
                setSearch={setSearch}
                setSearchVal={setSearchVal}
                currentProspect={currentProspect}
                setCurrentProspect={setCurrentProspect}
                setAll={setAll}
                all={all}
              />
            )
          }
          {
            customers && !user ? (
              <Users setUser={setUser} setCurrentUser={setCurrentUser} />
            ) : !user && !customers && !calendar && !newSource && !newItem && !sources && !successfulConvert ? (
              <>
                <div className="overview-welcome fade">
                  <h1>Welcome back,</h1>
                  <h2>{admin}</h2>
                </div>
                {/*<div className="status-sheet fade">*/}
                {/*  <h1>This week:</h1>*/}
                {/*  <div className="sheet-section">*/}
                {/*    <div className="sheet-item completed">*/}
                {/*      <div className="sheet-item-flex">*/}
                {/*        <div className="checkbox-block">*/}
                {/*          <div className={`checkbox checked`}>*/}
                {/*            <i className="fa fa-check"/>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <p className="date">MON, JAN 6 at 8:00 AM ET</p>*/}
                {/*        <p className="staff">Brad</p>*/}
                {/*        <p className="memo">Follow-up with John Smith</p>*/}
                {/*      </div>*/}
                {/*      <img src={"https://www.pcarmarket.com/static/media/uploads/galleries/photos/uploads/galleries/18019-nola-black-boxster/.thumbnails/Photo_Mar_23_12_04_55_PM.jpg/Photo_Mar_23_12_04_55_PM-tiny-600x0.jpg"} />*/}
                {/*    </div>*/}
                {/*    <div className="sheet-item completed">*/}
                {/*      <div className="sheet-item-flex">*/}
                {/*        <div className="checkbox-block">*/}
                {/*          <div className={`checkbox checked`}>*/}
                {/*            <i className="fa fa-check"/>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <p className="date">TUE, JAN 7 at 4:00 PM ET</p>*/}
                {/*        <p className="staff">Brett</p>*/}
                {/*        <p className="memo">Call with Eric Anderson to chat about setting up a new auction</p>*/}
                {/*      </div>*/}
                {/*      <img src={'https://www.pcarmarket.com/static/media/uploads/galleries/photos/uploads/galleries/v-0018031-nj-kevin-ginsberg-2015-porsche-911-carrera-4s/.thumbnails/A-015.jpg/A-015-tiny-600x0.jpg'} />*/}
                {/*    </div>*/}
                {/*    <div className="sheet-item">*/}
                {/*      <div className="sheet-item-flex">*/}
                {/*        <div className="checkbox-block">*/}
                {/*          <div className={`checkbox`}>*/}
                {/*            <i className="fa fa-check"/>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <p className="date">WED, JAN 8 at 10:00 AM ET</p>*/}
                {/*        <p className="staff">Kai</p>*/}
                {/*        <p className="memo">Get images for auction from photographer</p>*/}
                {/*      </div>*/}
                {/*      <img src={'https://www.pcarmarket.com/static/media/uploads/galleries/photos/uploads/galleries/17915-spady-corvette-grand-spoer/.thumbnails/1.jpg/1-tiny-600x0.jpg'} />*/}
                {/*    </div>*/}
                {/*    <div className="sheet-item">*/}
                {/*      <div className="sheet-item-flex">*/}
                {/*        <div className="checkbox-block">*/}
                {/*          <div className={`checkbox`}>*/}
                {/*            <i className="fa fa-check"/>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <p className="date">WED, JAN 8 at 2:00 PM ET</p>*/}
                {/*        <p className="staff">Kai</p>*/}
                {/*        <p className="memo">Weekly meeting with Brett and Brad</p>*/}
                {/*      </div>*/}
                {/*      <img src={'https://www.pcarmarket.com/static/media/uploads/galleries/photos/uploads/galleries/18035-matthias-red-jaguar-convertible/.thumbnails/IMG_8442.jpg/IMG_8442-tiny-600x0.jpg'} />*/}
                {/*    </div>*/}
                {/*    <div className="sheet-item">*/}
                {/*      <div className="sheet-item-flex">*/}
                {/*        <div className="checkbox-block">*/}
                {/*          <div className={`checkbox`}>*/}
                {/*            <i className="fa fa-check"/>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <p className="date">THU, JAN 9 at 7:00 AM ET</p>*/}
                {/*        <p className="staff">Brad</p>*/}
                {/*        <p className="memo">Call Powersmotorsports about 1972 PORSCHE 914 3.0L RACE CAR auction</p>*/}
                {/*      </div>*/}
                {/*      <img src={'https://www.pcarmarket.com/static/media/uploads/galleries/photos/uploads/galleries/17815-schedoni-luggage/.thumbnails/IMG_5423_VYO0Bjv.jpeg/IMG_5423_VYO0Bjv-tiny-600x0.jpeg'} />*/}
                {/*    </div>*/}
                {/*    <div className="sheet-item">*/}
                {/*      <div className="sheet-item-flex">*/}
                {/*        <div className="checkbox-block">*/}
                {/*          <div className={`checkbox`}>*/}
                {/*            <i className="fa fa-check"/>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <p className="date">FRI, JAN 10 at 1:00 PM ET</p>*/}
                {/*        <p className="staff">Brad</p>*/}
                {/*        <p className="memo">Lorem ipsum</p>*/}
                {/*      </div>*/}
                {/*      <img src={'https://www.pcarmarket.com/static/media/uploads/galleries/photos/uploads/galleries/18043-mark-david-turbo-992-cab-blue/.thumbnails/EBAD91A6-6EF4-45F1-83D5-D65BCB3BC16A.jpg/EBAD91A6-6EF4-45F1-83D5-D65BCB3BC16A-tiny-600x0.jpg'} />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <div className="sheet-section">*/}

                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className="charts fade">*/}
                {/*  <div className="chart">*/}
                {/*    <Bar data={data} options={options} height={200} />*/}
                {/*  </div>*/}
                {/*  <div className="chart">*/}
                {/*    <Line data={data2} options={options2} height={200} />*/}
                {/*  </div>*/}
                {/*</div>*/}
              </>
            ) : null
          }
          { calendar && <Calendar /> }
        </div>
      </div>
    </div>
  );
};

export default Dashboard;