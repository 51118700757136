import { useState } from "react";

/**
 * Individual Prospect
 * @param setCurrentProspect
 * @param setNewSource
 * @param setSources
 * @param p
 * @param humanDateFormat
 * @param setSelectedProspects
 * @param selectedProspects
 * @returns {JSX.Element}
 * @constructor
 */

const Prospect = ({setCurrentProspect, setNewSource, setSources, p, humanDateFormat, setSelectedProspects, selectedProspects}) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="prospect" key={p.id}>
      <div className="checkbox-block">
        <div className={`checkbox ${checked ? 'checked' : ''}`} onClick={() => {
          setChecked(!checked);
          if (checked) {
            /**
             * Remove from selected prospect list
             */
            setSelectedProspects(selectedProspects.filter(e => e !== p.id));
          } else {
            /**
             * Add to selected prospect list
             */
            setSelectedProspects(prospects => [...prospects, p.id]);
          }
        }}>
          <i className="fa fa-check"/>
        </div>
      </div>
      <div key={p.id} className={`source ${checked ? 'selectedSource' : ''}`} onClick={() => {
        setCurrentProspect(p.id);
        setNewSource(true);
        setSources(false);
      }}>
        <div className="s-cat type">
          <p>{p.category}</p>
        </div>
        <div className="s-cat date">
          <p>{humanDateFormat}</p>
        </div>
        <div className="s-cat tempcat">
          <p className={`temp ${p.temperature}`}>{p.temperature}</p>
        </div>
        <div className="s-cat title">
          <p>{p.title ? p.title : 'New Prospect'}</p>
        </div>
        <div className="s-cat sourcer">
          <p>{p.sourcer ? p.sourcer : 'Unspecified'}</p>
        </div>
        <div className="s-cat sourcer">
          <p>{p.sales_agent ? p.sales_agent : 'Unassigned'}</p>
        </div>
        <div className="s-cat make">
          <p>{p.make ? p.make : 'Unspecified'}</p>
        </div>
        <div className="s-cat model">
          <p>{p.model ? p.model : 'Unspecified'}</p>
        </div>
        <div className="s-cat image">
          <img src={p.images.length  ? p.images[0] : '/placeholder.png'} alt={p.title} />
        </div>
      </div>
    </div>
  );
}

export default Prospect;