import updateFirebase from "../components/updateFirebase";
import saveToFirebase from "../components/saveToFirebase";
import removeFromFirebase from "../components/removeFromFirebase";
import asana from "asana";

const NewItem = ({ setSuccessfulConvert, prospectData, setProspectData, setNewSource, setSources, currentProspect, setCurrentProspect, setNewItem, newItem, newSource, prospects }) => {
  /**
   * ASANA SETUP
   */
  const asana = require('asana');
  const client = asana.Client.create().useAccessToken(process.env.REACT_APP_ASANA);

  return (
    <form className="fade edit-user">
      <div className="user-header">
        <div className="user-header-left">
          <h2><i className="fa fa-arrow-left user-back" onClick={() => {
            setCurrentProspect(null);
            setNewSource(false);
            setSources(true);
          }}></i> <strong>{currentProspect ? prospectData.title : 'New Item Prospect'}</strong></h2>
          {
            !currentProspect && (
              <button className="switch" onClick={() => {
                setNewSource(!newSource);
                setNewItem(!newItem);
              }}>
                Switch to Car <i className="fa fa-car"></i>
              </button>
            )
          }
        </div>
        <div className="user-header-right">
          <button className="mr" onClick={(e) => {
            e.preventDefault();
            if (currentProspect) {
              /**
               * Update existing prospect
               */
              updateFirebase('prospects', prospectData, prospectData.id).then(() => {
                setCurrentProspect(null);
                setNewSource(false);
                setSources(true);
              })
            } else {
              /**
               * Save new prospect to Firebase
               */
              saveToFirebase('prospects', prospectData).then(() => {
                setCurrentProspect(null);
                setNewSource(false);
                setSources(true);
              });
            }
          }}>Save <i className="fa fa-check"></i></button>
          {
            currentProspect ? (
              <>
                <button className="close-lead mr" onClick={(e) => {
                  e.preventDefault();
                  removeFromFirebase('prospects', prospectData.id).then(() => {
                    setCurrentProspect(null);
                    setNewSource(false);
                    setSources(true);
                  });
                }}>Delete <i className="fa fa-close"></i></button>
                <input type="submit" value="Create Asana Task" className="ctl" onClick={(e) => {
                  e.preventDefault();
                  console.log('Sending to Asana...');
                  client.tasks.createTask({workspace: '1114168641987265', projects: ['1203323119571099'], name: prospectData.title, custom_fields: prospectData}, )
                      .then((result) => {
                        setNewSource(false);
                        setSuccessfulConvert(true);
                        console.log('Successfully created Asana task.')
                        console.log(result);
                      })
                      .catch((error) => {
                        console.error(error);
                      })
                }} />
              </>
            ) : null
          }
        </div>
      </div>
      <div className="user-section call-logs">
        <div className="form-errors">
          <p className="error">Example error</p>
          <p className="error">Example error</p>
        </div>
        <h3>Prospect Details</h3>
        <div className="user-divider"></div>
        <div className="inputs">
          <div className="input-block">
            <label>Status</label>
            <br/>
            <select className="input-error">
              <option>Unqualified</option>
              <option>Qualified</option>
              <option>Dealer Qualified</option>
              <option>Converted</option>
              <option>Failed</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Sourcer</label>
            <br/>
            <select defaultValue={prospectData.sourcer} value={prospectData.sourcer} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  sourcer: e.target.value});
            }}>
              <option value="Unassigned">Unassigned</option>
              <option value="Brad">Brad</option>
              <option value="Adam">Adam</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Sales Agent</label>
            <br/>
            <select defaultValue={prospectData.sales_agent} value={prospectData.sales_agent} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  sales_agent: e.target.value});
            }}>
              <option value="Unassigned">Unassigned</option>
              <option value="Brad">Brad</option>
              <option value="Adam">Adam</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Temperature</label>
            <br/>
            <select defaultValue={prospectData.temperature} value={prospectData.temperature} className="input-error" defaultValue="warm" onChange={(e) => {
              setProspectData({...prospectData,  temperature: e.target.value});
            }}>
              <option value="hot">Hot (1)</option>
              <option value="warm">Warm (2)</option>
              <option value="cold">Cold (3)</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>Permission to Charge Card</label>
            <br/>
            <select className="input-error">
              <option>False</option>
              <option>True</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Last 4 Digits of Card</label>
            <br/>
            <input type="text" className="input-error" />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block"></div>
          <div className="input-block"></div>
        </div>
      </div>
      <div className="user-section">
        <h3>Item Details</h3>
        <div className="user-divider"></div>
        <div className="inputs">
          <div className="input-block">
            <label>Title</label>
            <br/>
            <input type="text" className="input-error" />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Primary Contact</label>
            <br/>
            <input type="text" className="input-error" />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Link</label>
            <br/>
            <input type="text" className="input-error" />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Notes</label>
            <br/>
            <input type="text" className="input-error" />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>Country</label>
            <br/>
            <input type="text" className="input-error" />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Postal Code</label>
            <br/>
            <input type="text" className="input-error" />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Phone Number</label>
            <br/>
            <input type="text" className="input-error" />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Reserve auction</label>
            <br/>
            <select defaultValue={prospectData.reserve_auction} value={prospectData.reserve_auction} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  reserve_auction: e.target.value});
            }}>
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>Reserve price</label>
            <br/>
            <input defaultValue={prospectData.reserve_price} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  reserve_price: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Other Sales</label>
            <br/>
            <select className="input-error">
              <option>No</option>
              <option>Yes</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>If so, where?</label>
            <br/>
            <input type="text" className="input-error" />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block"></div>
        </div>
        <div className="inputs">
          <div className="inputs">
            <div className="input-block">
              <label>Description</label>
              <br/>
              <textarea type="text" className="input-error" />
              <p className="error">Example error</p>
              <p className="error">Example error</p>
            </div>
            <div className="input-block">
              <label>Condition</label>
              <br/>
              <textarea type="text" className="input-error" />
              <p className="error">Example error</p>
              <p className="error">Example error</p>
            </div>
          </div>
        </div>
      </div>
      <div className="user-section images">
        <h3>Images</h3>
        <p>Drop images here to upload</p>
        <br />
        <div className="inputs">
          <div className="input">
            <div className="image-outline">
              <i className="fa fa-image" />
            </div>
          </div>
        </div>
        <p className="error">Example error</p>
        <p className="error">Example error</p>
      </div>
      <div className="user-section call-logs">
        <h3>Contact Logs</h3>
        <br />
        <div className="new-contact">
          <input type="text" placeholder="Add new contact log" className="input-error" />
          <button>Add</button>
        </div>
        <p className="error">Example error</p>
        <p className="error">Example error</p>
        <div className="user-divider"></div>
        {
          prospectData.contact_logs.map((log) => {
            const dateObject = new Date(log.timestamp);
            const humanDateFormat = dateObject.toLocaleString()
            return (
              <p><strong>{humanDateFormat}</strong> {log.text} <i className="fa fa-times"></i></p>
            )
          })
        }
      </div>
      {
        currentProspect && prospectData.phone_number ? (
          <div className="user-section duplicates call-logs">
            <h3>Duplicates</h3>
            <br />
            <p>Other prospects with the number <em>{prospectData.phone_number.replace(/\D+/g, '')}</em></p>
            <div className="user-divider"></div>
            {
              prospects.length && prospects.map((p) => {
                if (p.phone_number.replace(/\D+/g, '') === prospectData.phone_number.replace(/\D+/g, '')) {
                  if (p.id === prospectData.id) return;
                  if (!prospectData.phone_number.length) return;
                  return (
                    <p className="p-duplicate" onClick={() => {
                      setCurrentProspect(p.id);
                      window.scrollTo(0, 0);
                    }}>{p.title} <i className="fa fa-external-link"></i></p>
                  )
                }
              })
            }
          </div>
        ) : null
      }
    </form>
  )
}

export default NewItem;