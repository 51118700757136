import Firebase from './Firebase';
import { deleteDoc, doc } from "firebase/firestore";

/**
 * Remove from Firebase
 * @param location
 * @param id
 */
const removeFromFirebase = async (location, id) => {
  try {
    await deleteDoc(doc(Firebase, location, id));
    console.log("Document deleted with ID: ", id);
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
};

export default removeFromFirebase;