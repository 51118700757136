import setCookie from "../components/SetCookie";
import { useState } from "react";

const Login = ({ forgotPassword, setForgotPassword, setLoggedIn, setAdmin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  /**
   * User authentication
   * @constructor
   */
  const Auth = () => {
    setError(null);
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    fetch(`https://www.pcarmarket.com/api/login/?api_key=${process.env.REACT_APP_API_KEY}`, {
      method: 'POST',
      credentials: "include",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          //setCookie('sessionid', data.session_key, 7);
          setAdmin(username);
          setLoggedIn(true);
        } else {
          setError(data.server_errors[0]);
          setLoggedIn(false);
        }
      })
      .catch((e) => {
        setError(e);
        console.error(e);
        setLoggedIn(false);
      })
  }

  return (
    <div className="login">
      <div className="fade login-inner">
        <img src="/pcar.png" alt="PCARMARKET" />
        {
          forgotPassword ? (
            <>
              <div className="input-block">
                <label>Username</label>
                <br />
                <input type="text" className="input-error" />
                <p className="error">Example error</p>
                <p className="error">Example error</p>
              </div>
              <button onClick={() => {
                // TODO: Send password reset
                setForgotPassword(false)
              }}>Send reset email</button>
              <p className="error">Example error</p>
              <p className="error">Example error</p>
              <br />
              <p className="forgot-password" onClick={() => setForgotPassword(false)}>Back to login</p>
            </>
          ) : (
            <>
              <div className="input-block" style={{ marginBottom: 15 }}>
                <label>Username</label>
                <br />
                <input type="text" className="input-error" onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div className="input-block">
                <label>Password</label>
                <br />
                <input type="password" className="input-error" onChange={(e) => setPassword(e.target.value)} />
                {
                  error && (
                    <>
                      <br /><br />
                      <p style={{ color: '#ff0000' }}>{error.toString()}</p>
                    </>
                  )
                }
              </div>
              <br />
              <button className={!username.length || !password.length ? 'disabled' : ''} onClick={() => {
                Auth()
              }}>Sign in</button>
              <p className="error">Example error</p>
              <p className="error">Example error</p>
            </>
          )
        }
      </div>
    </div>
  )
}

export default Login;