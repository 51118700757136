import {useEffect, useState} from "react";

const User = ({ setUser, currentUser }) => {

  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(null);
  const [addressLine1, setAddressLine1] = useState(null);
  const [addressLine2, setAddressLine2] = useState(null);
  const [country, setCountry] = useState(null);
  const [phone, setPhone] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [zip, setZip] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [deactivated, setDeactivated] = useState(null);
  const [howHeard, setHowHeard] = useState(null);
  const [interestType, setInterestType] = useState(null);
  const [notes, setNotes] = useState(null);
  const [accountManager, setAccountManager] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneVerified, setPhoneVerified] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [staff, setStaff] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [userAmount, setUserAmount] = useState(50);

  useEffect(() => {
    // Get user details
    fetch(`https://www.pcarmarket.com/api/users/detail/${currentUser}/?api_key=${process.env.REACT_APP_API_KEY}`, {
      method: 'GET',
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setUsername(data.user.username);
        setEmail(data.user.email);
        setFirstName(data.user.first_name);
        setLastName(data.user.last_name);
        setIsActive(data.user.is_active);
        setAcceptedTerms(data.user.bidding_account_details.accepted_terms);
        setAddressLine1(data.user.bidding_account_details.address_line_1);
        setAddressLine2(data.user.bidding_account_details.address_line_2);
        setCountry(data.user.bidding_account_details.country);
        setPhone(data.user.bidding_account_details.phone_number);
        setStripe(data.user.bidding_account_details.stripe_customer_id);
        setZip(data.user.bidding_account_details.zip_code);
        setDeactivated(data.user.user_account_details.deactivated);
        setHowHeard(data.user.user_account_details.how_heard);
        setInterestType(data.user.user_account_details.interest_type);
        setNotes(data.user.user_account_details.notes);
        setAccountManager(data.user.user_account_details.account_manager)
        setPhoneNumber(data.user.user_account_details.phone_number);
        setPhoneVerified(data.user.user_account_details.phone_number_verified);
        setZipCode(data.user.user_account_details.zip_code);
      })
      .catch((e) => {
        console.error(e);
      })
    // Get admin users
    fetch(`https://www.pcarmarket.com/api/users/list/?staff=true&api_key=${process.env.REACT_APP_API_KEY}&start=0&end=${userAmount.toString()}&order_by=username&search=${searchVal}`, {
      method: 'GET',
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setStaff(data.users);
        console.log("Staff:");
        console.log(data);
      })
      .catch((e) => {
        console.error(e);
      })
  }, []);

  return (
    <form className="fade edit-user">
      <div className="user-header">
        <div className="user-header-left">
          <h2><i className="fa fa-arrow-left user-back" onClick={() => setUser(false)}></i> <strong>{firstName} {lastName} ({username})</strong></h2>
          <div className="label">User</div>
          { isActive && <div className="label green">Active</div> }
          { acceptedTerms && <div className="label green">Billing Enabled</div> }
          { phoneVerified && <div className="label green">Phone Verified</div> }
          { deactivated && <div className="label red">Deactivated</div> }
          {/*<div className="label">Dealer</div>*/}
        </div>
        <div className="user-header-right">
          <button className="mr" onClick={(e) => {
            e.preventDefault();
            setSuccess(null);
            setError(null);
            const updateBody = {
              "user_account_details": {
                ...(accountManager && { "account_manager": accountManager }),
                "notes": notes,
              }
            };
            console.log(JSON.stringify(updateBody));
            fetch(`https://www.pcarmarket.com/api/users/update/${currentUser}/?api_key=${process.env.REACT_APP_API_KEY}`, {
              method: 'PATCH',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(updateBody)
            })
              .then((res) => {
                if (!res.ok) {
                  return res.json().then(json => {
                    throw new Error(`Status: ${res.status} - ${json.message || res.statusText}`);
                  }).catch(() => {
                    throw new Error(`Status: ${res.status} - ${res.statusText}`);
                  });
                }
                return res.json();
              })
              .then((data) => {
                console.log(data);
                setSuccess('User updated successfully.')
              })
              .catch((e) => {
                console.error(e);
                setError(`Failed to update user: ${e.message}`);
              })
          }}>Save <i className="fa fa-check"></i></button>
          <button onClick={() => setUser(false)}>Cancel <i className="fa fa-ban"></i></button>
        </div>
      </div>
      { success && <p className="user-success">{ success }</p> }
      { error && <p className="user-error">{ error }</p> }
      <div className="user-section">
        <div className="form-errors">
          <p className="error">Example error</p>
          <p className="error">Example error</p>
        </div>
        <h3>Account</h3>
        <div className="user-divider"></div>
        <div className="inputs">
          <div className="input-block">
            <label>Username</label>
            <br/>
            <input type="text" className="input-error" value={username} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Email</label>
            <br/>
            <input type="email" className="input-error" value={email} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Password</label>
            <br/>
            <input type="password" className="input-error" readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>Notes</label>
            <br/>
            <input type="text" className="input-error editable" value={notes} onChange={(e) => setNotes(e.target.value)} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Source</label>
            <br/>
            <input type="text" className="input-error" value={howHeard && howHeard[1]} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Interest</label>
            <br/>
            <input type="text" className="input-error" value={interestType && interestType[1]} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>Account Manager</label>
            <br/>
            <select
              className="input-error"
              value={accountManager}
              onChange={(e) => setAccountManager(e.target.value)}
            >
              <option value="" disabled>Select</option>
              {staff.length > 0 && staff.map((admin) => (
                <option key={admin.username} value={admin.username}>
                  {admin.username}
                </option>
              ))}
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block"></div>
          <div className="input-block"></div>
        </div>
      </div>
      <div className="user-section">
        <h3>Personal Information</h3>
        <div className="user-divider"></div>
        <div className="inputs">
          <div className="input-block">
            <label>First name</label>
            <br/>
            <input type="text" className="input-error" value={firstName} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Last name</label>
            <br/>
            <input type="text" className="input-error" value={lastName} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Stripe customer ID</label>
            <br/>
            <input type="text" className="input-error" value={stripe} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Phone number</label>
            <br/>
            <input type="text" className="input-error" value={phone || phoneNumber} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>Address line 1</label>
            <br/>
            <input type="text" className="input-error" value={addressLine1} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Address line 2</label>
            <br/>
            <input type="text" className="input-error" value={addressLine2} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Country</label>
            <br/>
            <input type="text" className="input-error" value={country && country[1]} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Zip code</label>
            <br/>
            <input type="text" className="input-error" value={zip || zipCode} readOnly />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
      </div>
      {/*<div className="user-section">*/}
      {/*  <h3>Dealer</h3>*/}
      {/*  <div className="user-divider"></div>*/}
      {/*  <div className="inputs">*/}
      {/*    <div className="input-block">*/}
      {/*      <label>Dealer license number</label>*/}
      {/*      <br/>*/}
      {/*      <input type="text" className="input-error" />*/}
      {/*      <p className="error">Example error</p>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*    </div>*/}
      {/*    <div className="input-block">*/}
      {/*      <label>Expiration date <span>(EXPIRED)</span></label>*/}
      {/*      <br/>*/}
      {/*      <input type="date" className="expired input-error" />*/}
      {/*      <p className="error">Example error</p>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="user-section">*/}
      {/*  <h3>Permissions</h3>*/}
      {/*  <div className="user-divider"></div>*/}
      {/*  <div className="inputs">*/}
      {/*    <div className="input-block">*/}
      {/*      <label>Role</label>*/}
      {/*      <br/>*/}
      {/*      <select className="input-error">*/}
      {/*        <option>User</option>*/}
      {/*        <option>Staff</option>*/}
      {/*        <option>Admin</option>*/}
      {/*      </select>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*    </div>*/}
      {/*    <div className="input-block">*/}
      {/*      <label>Disallow Login</label>*/}
      {/*      <br/>*/}
      {/*      <select className="input-error">*/}
      {/*        <option>No</option>*/}
      {/*        <option>Yes</option>*/}
      {/*      </select>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*    </div>*/}
      {/*    <div className="input-block">*/}
      {/*      <label>Verified Email</label>*/}
      {/*      <br/>*/}
      {/*      <select className="input-error">*/}
      {/*        <option>Yes</option>*/}
      {/*        <option>No</option>*/}
      {/*      </select>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*    </div>*/}
      {/*    <div className="input-block">*/}
      {/*      <label>Billing Enabled</label>*/}
      {/*      <br/>*/}
      {/*      <select className="input-error">*/}
      {/*        <option>Yes</option>*/}
      {/*        <option>No</option>*/}
      {/*      </select>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="user-section">*/}
      {/*  <h3>Add to Calendar</h3>*/}
      {/*  <div className="user-divider"></div>*/}
      {/*  <div className="inputs">*/}
      {/*    <div className="input-block">*/}
      {/*      <label>Note</label>*/}
      {/*      <br/>*/}
      {/*      <input type="text" className="input-error" />*/}
      {/*      <p className="error">Example error</p>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*    </div>*/}
      {/*    <div className="input-block">*/}
      {/*      <label>Type</label>*/}
      {/*      <br/>*/}
      {/*      <select className="input-error">*/}
      {/*        <option>Initial call</option>*/}
      {/*        <option>Follow-up</option>*/}
      {/*        <option>Etc</option>*/}
      {/*      </select>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*    </div>*/}
      {/*    <div className="input-block">*/}
      {/*      <label>Date</label>*/}
      {/*      <br/>*/}
      {/*      <input type="date" className="input-error" />*/}
      {/*      <p className="error">Example error</p>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*    </div>*/}
      {/*    <div className="input-block">*/}
      {/*      <label>Time</label>*/}
      {/*      <br/>*/}
      {/*      <input type="time" id="time" name="time"*/}
      {/*             min="00:00" max="24:00" className="input-error" required/>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*      <p className="error">Example error</p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <button>Submit</button>*/}
      {/*</div>*/}
      {/*<div className="user-section">*/}
      {/*  <h3>Calendar</h3>*/}
      {/*  <div className="user-divider"></div>*/}
      {/*  <div className="reminder-list">*/}

      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="user-section stats">*/}
      {/*  <h3>Statistics</h3>*/}
      {/*  <div className="user-divider"></div>*/}
      {/*  <p>Joined on <strong>Jan 4th, 2019</strong></p>*/}
      {/*  <p>Last logged in <strong>Oct 27th, 2021</strong></p>*/}
      {/*  <p><strong>16</strong> comments</p>*/}
      {/*  <p><strong>1</strong> auction</p>*/}
      {/*  <p><strong>4</strong> bids</p>*/}
      {/*</div>*/}
    </form>
  )
}

export default User;