import getFromFirebase from "../components/getFromFirebase";
import {useEffect, useState} from "react";
import Prospect from "./Prospect";
import asana from "asana";
import removeFromFirebase from "../components/removeFromFirebase";
import updateFirebase from "../components/updateFirebase";

const Prospects = ({
  unqualified,
  qualified,
  dealerQualified,
  converted,
  failed,
  setUnqualified,
  setQualified,
  setDealerQualified,
  setConverted,
  setFailed,
  setSources,
  sources,
  setNewSource,
  prospects,
  setProspects,
  search,
  searchVal,
  setSearch,
  setSearchVal,
  currentProspect,
  setCurrentProspect,
  setAll,
  all
}) => {

  const loadProspects = async () => {
    const p = await getFromFirebase("prospects");
    return p;
  }

  const Search = (val) => {
    if (val.length) {
      setSearch(true);
    } else {
      setSearch(false);
    }

    setSearchVal(val);
  }

  const [selectedProspects, setSelectedProspects] = useState([]);

  /**
   * ASANA SETUP
   */
  const asana = require('asana');
  const client = asana.Client.create().useAccessToken(process.env.REACT_APP_ASANA);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const [bulkAgent, setBulkAgent] = useState('');

  /**
   * Load prospect data initially, and update on bulk actions
   */
  useEffect(() => {
    /**
     * Get Prospect data
     */
    loadProspects().then((p) => setProspects(p));
  }, [creating, deleting, assigning, sources]);

  /**
   * Bulk assign sales agent
   * @param list
   */
  const bulkAssignAgent = (list) => {
    if (!list.length) return;
    setAssigning(true);
    list.forEach((id) => {
      /**
       * Find prospect
       */
      let prospectData;
      prospects.map((prospect) => {
        if (prospect.id === id) prospectData = prospect;
      });
      /**
       * Set sales agent
       * @type {string}
       */
      prospectData.sales_agent = bulkAgent;
      /**
       * Update Firebase
       */
      updateFirebase('prospects', prospectData, id);
    });
    setAssigning(false);
  };

  /**
   * Bulk create asana tasks
   * @param list
   */
  const bulkCreateAsana = (list) => {
    if (!list.length) return;
    setCreating(true);
    list.forEach((id) => {
      /**
       * Find prospect
       */
      let prospectData;
      prospects.map((prospect) => {
        if (prospect.id === id) prospectData = prospect;
      });
      /**
       * If doesn't exist, cancel
       */
      if (!prospectData) return;
      /**
       * Prepare contact logs for description
       * @type {string}
       */
      let description = "";
      if (prospectData.contact_logs.length) {
        prospectData.contact_logs.forEach((log) => {
          const dateObject = new Date(log.timestamp);
          const humanDateFormat = dateObject.toLocaleString()
          description += `, ${humanDateFormat}: ${log.text}`;
        })
      }
      /**
       * Create this asana task
       */
      client.tasks.createTask({
        workspace: '1114168641987265',
        projects: ['1203323119571099'],
        name: prospectData.title,
        notes: description,
        custom_fields: {
          '1203320274351984': prospectData.title.toString(),
          '1203320274351986': prospectData.sourcer.toString(),
          '1203320274351974': prospectData.sales_agent.toString(),
          '1203529693247417': prospectData.has_title.toString(),
          '1203529693247419': prospectData.title_received.toString(),
          '1203529693247421': prospectData.card_permission.toString(),
          '1203529693247423': prospectData.card_digits.toString(),
          '1203320274351988': prospectData.primary_contact.toString(),
          '1203320274351990': prospectData.link.toString(),
          '1203320274351992': prospectData.notes.toString(),
          '1203320274351996': prospectData.make.toString(),
          '1203320274351998': prospectData.model.toString(),
          '1203320274352000': prospectData.exterior_color.toString(),
          '1203320274352002': prospectData.interior_color.toString(),
          '1203320274352004': prospectData.options.toString(),
          '1203320274352006': prospectData.type.toString(),
          '1203320274352008': prospectData.mileage.toString(),
          '1203320274352010': prospectData.vin.toString(),
          '1203320274352012': prospectData.year.toString(),
          '1203320274352014': prospectData.country.toString(),
          '1203320274352016': prospectData.postal_code.toString(),
          '1203320274352018': prospectData.phone_number.toString(),
          '1203320274352020': prospectData.odometer_value.toString(),
          '1203320274352022': prospectData.odometer_units.toString(),
          '1203320274352024': prospectData.reserve_auction.toString(),
          '1203320274352026': prospectData.reserve_price.toString(),
          '1203320274352032': prospectData.category.toString(),
          '1203320274352043': prospectData.id.toString(),
        }
      }, )
        .then((result) => {
          prospectData.images.forEach((url) => {
            client.attachments.createAttachmentForObject({url: url, parent: result.gid})
              .then((res) => {
                console.log(res);
              });
          });
          setCreating(false);
          console.log('Successfully created Asana task.')
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
          setCreating(false);
        })
    });
    setCreating(false);
  };

  /**
   * Bulk delete prospects
   * @param list
   */
  const bulkDelete = (list) => {
    if (!list.length) return;
    setDeleting(true);
    list.forEach((id) => {
      removeFromFirebase('prospects', id);
    });
    setDeleting(false);
  };

  return (
    <div className="fade">
      {
        selectedProspects.length ? (
          <div className="bulk-actions">
            <label>Assign to Sales Agent:</label>
            <div className="input-block">
              <select className="input-error" onChange={(e) => setBulkAgent(e.target.value)}>
                <option value="Adam">Adam</option>
                <option value="Brad">Brad</option>
                <option value="Kai">Kai</option>
                <option value="Brett">Brett</option>
              </select>
            </div>
            <button onClick={() => bulkAssignAgent(selectedProspects)}>{assigning ? <>Loading...</> : <>Assign</>}</button>
            <p>|</p>
            <button className="ctl" onClick={() => bulkCreateAsana(selectedProspects)}>{creating ? <>Loading...</> : <>Create Asana Tasks</>}</button>
            {deleting ? <i className="fa fa-loading" /> : <i className="fa fa-trash" onClick={() => bulkDelete(selectedProspects)} />}
          </div>
        ) : null
      }
      <div className="customers-header">
        { all && <h2>All Prospects</h2> }
        { unqualified && <h2>Unqualified Prospects</h2> }
        { qualified && <h2>Qualified Prospects</h2> }
        { dealerQualified && <h2>Dealer Qualified Prospects</h2> }
        { converted && <h2>Converted Prospects</h2> }
        { failed && <h2>Failed Prospects</h2> }
        <div className="customers-filters">
          <div className="checkbox-block" onClick={() => {
            setAll(true);
            setUnqualified(false);
            setQualified(false);
            setDealerQualified(false);
            setConverted(false);
            setFailed(false);
          }}>
            <div className={`checkbox ${all ? 'checked' : ''}`}>
              <i className="fa fa-check" />
            </div>
            <label>All Prospects</label>
          </div>
          <div className="checkbox-block" onClick={() => {
            setAll(false);
            setUnqualified(true);
            setQualified(false);
            setDealerQualified(false);
            setConverted(false);
            setFailed(false);
          }}>
            <div className={`checkbox ${unqualified ? 'checked' : ''}`}>
              <i className="fa fa-check" />
            </div>
            <label>Unqualified Prospects</label>
          </div>
          <div className="checkbox-block" onClick={() => {
            setAll(false);
            setUnqualified(false);
            setQualified(true);
            setDealerQualified(false);
            setConverted(false);
            setFailed(false);
          }}>
            <div className={`checkbox ${qualified ? 'checked' : ''}`}>
              <i className="fa fa-check" />
            </div>
            <label>Qualified Prospects</label>
          </div>
          <div className="checkbox-block" onClick={() => {
            setAll(false);
            setUnqualified(false);
            setDealerQualified(true);
            setQualified(false);
            setConverted(false);
            setFailed(false);
          }}>
            <div className={`checkbox ${dealerQualified ? 'checked' : ''}`}>
              <i className="fa fa-check" />
            </div>
            <label>Dealer Qualified Prospects</label>
          </div>
          <div className="checkbox-block" onClick={() => {
            setAll(false);
            setUnqualified(false);
            setQualified(false);
            setDealerQualified(false);
            setConverted(true);
            setFailed(false);
          }}>
            <div className={`checkbox ${converted ? 'checked' : ''}`}>
              <i className="fa fa-check" />
            </div>
            <label>Converted Prospects</label>
          </div>
          <div className="checkbox-block" onClick={() => {
            setAll(false);
            setUnqualified(false);
            setQualified(false);
            setDealerQualified(false);
            setConverted(false);
            setFailed(true);
          }}>
            <div className={`checkbox ${failed ? 'checked' : ''}`}>
              <i className="fa fa-check" />
            </div>
            <label>Failed Prospects</label>
          </div>
          <input type="text" placeholder="Search prospects" onChange={(e) => Search(e.target.value)} />
        </div>
      </div>
      <br />
      <div className="sources">
        <div className="source-labels">
          <div className="s-cat type">
            <p>Prospect</p>
          </div>
          <div className="s-cat date">
            <p>Date Created</p>
          </div>
          <div className="s-cat tempcat">
            <p>Temperature</p>
          </div>
          <div className="s-cat title">
            <p>Title</p>
          </div>
          <div className="s-cat sourcer">
            <p>Sourcer</p>
          </div>
          <div className="s-cat sourcer">
            <p>Sales Agent</p>
          </div>
          <div className="s-cat make">
            <p>Make</p>
          </div>
          <div className="s-cat model">
            <p>Model</p>
          </div>
          <div className="s-cat image">
            <p>Image</p>
          </div>
        </div>
        <>
          {
            prospects.length ? prospects.map((p) => {
              /**
               * Create readable date
               */
              const dateObject = new Date(p.timestamp);
              const humanDateFormat = dateObject.toLocaleString()
              if (search) {
                if (!p.title.toLowerCase().includes(searchVal.toLowerCase())
                  && !p.sourcer.toLowerCase().includes(searchVal.toLowerCase())
                  && !p.sales_agent.toLowerCase().includes(searchVal.toLowerCase())
                  && !p.make.toLowerCase().includes(searchVal.toLowerCase())
                  && !p.model.toLowerCase().includes(searchVal.toLowerCase())
                  && !p.temperature.toLowerCase().includes(searchVal.toLowerCase())
                  && !humanDateFormat.toLowerCase().includes(searchVal.toLowerCase())
                  && !p.category.toLowerCase().includes(searchVal.toLowerCase())
                )
                  return null;
              }
              return (
                <Prospect
                  setCurrentProspect={setCurrentProspect}
                  setNewSource={setNewSource}
                  setSources={setSources}
                  p={p}
                  humanDateFormat={humanDateFormat}
                  setSelectedProspects={setSelectedProspects}
                  selectedProspects={selectedProspects}
                />
              )
            }) : (
              <>
                <br /><br />
                <p>No prospects found.</p>
              </>
            )
          }
        </>
      </div>
    </div>
  )
}

export default Prospects;