import Firebase from './Firebase';
import { updateDoc, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const storage = getStorage();

/**
 * Update Firebase
 * @param location
 * @param data
 * @param id
 */
const updateFirebase = async (location, data, id) => {
  try {
    /**
     * Upload any images
     */
    let newFiles = [];
    console.log(data);
    if (data.images && data.images.length) {
      await data.images.forEach((file) => {
        /**
         * Ignore images that are already uploaded
         */
        if (typeof file === 'string') return;
        /**
         * New file uploads
         * @type {StorageReference}
         */
        const storageRef = ref(storage, `${id}/` + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            console.error(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              newFiles.push(downloadURL);
              console.log('File available at', downloadURL);
              if (!newFiles.length) return;
              /**
               * Add newly uploaded images to the current array
               */
              data.images.forEach((img) => {
                if (typeof img === 'string') {
                  newFiles.push(img);
                }
              })
              data.images = newFiles;
              /**
               * Update the document in Firebase
               */
              updateDoc(doc(Firebase, location, id), data);
              console.log("Document updated with ID: ", id);
            });
          }
        );
      });
    } else {
      /**
       * Update the document in Firebase
       */
      console.log(data);
      await updateDoc(doc(Firebase, location, id), data);
      console.log("Document updated with ID: ", id);
    }
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export default updateFirebase;