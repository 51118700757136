import saveToFirebase from "../components/saveToFirebase";
import updateFirebase from "../components/updateFirebase";
import removeFromFirebase from "../components/removeFromFirebase";
import { useState } from "react";

const NewCar = ({
  setSuccessfulConvert,
  prospectData,
  setProspectData,
  setNewSource,
  setSources,
  currentProspect,
  setCurrentProspect,
  setNewItem,
  newItem,
  newSource,
  prospects
}) => {
  /**
   * ASANA SETUP
   */
  const asana = require('asana');
  const client = asana.Client.create().useAccessToken(process.env.REACT_APP_ASANA);
  const [creating, setCreating] = useState(false);

  /**
   * Drop image and display
   * @param e
   */
  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.startsWith('image/')) {
        setProspectData(prevState => ({
          ...prevState,
          images: [
            ...prevState.images,
            file
          ]
        }))
      }
    }
  };

  /**
   * Prevent default drag over
   * @param e
   */
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  /**
   * Delete an image
   * @param index
   */
  const handleImageClick = (index) => {
    let newImages = prospectData.images;
    newImages.splice(index, 1);
    setProspectData({...prospectData,  images: [...newImages]});
  };

  const [localImages, setLocalImages] = useState('');
  const [currentLog, setCurrentLog] = useState('');

  return (
    <form className="fade edit-user">
      <div className="user-header">
        <div className="user-header-left">
          <h2><i className="fa fa-arrow-left user-back" onClick={() => {
            setCurrentProspect(null);
            setNewSource(false);
            setSources(true);
          }}></i> <strong>{currentProspect ? prospectData.title : 'New Car Prospect'}</strong></h2>
          {
            !currentProspect && (
              <button className="switch" onClick={() => {
                setNewSource(!newSource);
                setNewItem(!newItem);
              }}>
                Switch to Item <i className="fa fa-book"></i>
              </button>
            )
          }
        </div>
        <div className="user-header-right">
          <button className="mr" onClick={(e) => {
            e.preventDefault();
            if (currentProspect) {
              /**
               * Update existing prospect
               */
              updateFirebase('prospects', prospectData, prospectData.id).then(() => {
                setCurrentProspect(null);
                setNewSource(false);
                setSources(true);
              })
            } else {
              /**
               * Save new prospect to Firebase
               */
              saveToFirebase('prospects', prospectData).then(() => {
                setCurrentProspect(null);
                setNewSource(false);
                setSources(true);
              });
            }
          }}>Save <i className="fa fa-check"></i></button>
          {
            currentProspect ? (
              <>
                <button className="close-lead mr" onClick={(e) => {
                  e.preventDefault();
                  removeFromFirebase('prospects', prospectData.id).then(() => {
                    setCurrentProspect(null);
                    setNewSource(false);
                    setSources(true);
                  });
                }}>Delete <i className="fa fa-close"></i></button>
                <input type="submit" value={creating ? 'Please wait...' : 'Create Asana Task'} className={`ctl ${creating ? 'disabled' : ''}`} onClick={(e) => {
                  e.preventDefault();
                  setCreating(true);
                  console.log('Sending to Asana...');
                  let description = "";
                  if (prospectData.contact_logs.length) {
                    prospectData.contact_logs.forEach((log) => {
                      const dateObject = new Date(log.timestamp);
                      const humanDateFormat = dateObject.toLocaleString()
                      description += `, ${humanDateFormat}: ${log.text}`;
                    })
                  }
                  client.tasks.createTask({
                    workspace: '1114168641987265',
                    projects: ['1203323119571099'],
                    name: prospectData.title,
                    notes: description,
                    custom_fields: {
                      '1203320274351984': prospectData.title.toString(),
                      '1203320274351986': prospectData.sourcer.toString(),
                      '1203320274351974': prospectData.sales_agent.toString(),
                      '1203529693247417': prospectData.has_title.toString(),
                      '1203529693247419': prospectData.title_received.toString(),
                      '1203529693247421': prospectData.card_permission.toString(),
                      '1203529693247423': prospectData.card_digits.toString(),
                      '1203320274351988': prospectData.primary_contact.toString(),
                      '1203320274351990': prospectData.link.toString(),
                      '1203320274351992': prospectData.notes.toString(),
                      '1203320274351996': prospectData.make.toString(),
                      '1203320274351998': prospectData.model.toString(),
                      '1203320274352000': prospectData.exterior_color.toString(),
                      '1203320274352002': prospectData.interior_color.toString(),
                      '1203320274352004': prospectData.options.toString(),
                      '1203320274352006': prospectData.type.toString(),
                      '1203320274352008': prospectData.mileage.toString(),
                      '1203320274352010': prospectData.vin.toString(),
                      '1203320274352012': prospectData.year.toString(),
                      '1203320274352014': prospectData.country.toString(),
                      '1203320274352016': prospectData.postal_code.toString(),
                      '1203320274352018': prospectData.phone_number.toString(),
                      '1203320274352020': prospectData.odometer_value.toString(),
                      '1203320274352022': prospectData.odometer_units.toString(),
                      '1203320274352024': prospectData.reserve_auction.toString(),
                      '1203320274352026': prospectData.reserve_price.toString(),
                      '1203320274352032': prospectData.category.toString(),
                      '1203320274352043': prospectData.id.toString(),
                    }
                  }, )
                    .then((result) => {
                      prospectData.images.forEach((url) => {
                        // TODO: fix
                        client.attachments.createAttachmentForObject({
                          url: url,
                          parent: result.gid,
                          name: url.split('/').pop(),
                          resource_subtype: 'external'
                        })
                          .then((res) => {
                            console.log(res);
                          });
                      });
                      setNewSource(false);
                      setSuccessfulConvert(true);
                      setCreating(false);
                      console.log('Successfully created Asana task.')
                      console.log(result);
                    })
                    .catch((error) => {
                      console.error(error);
                      setCreating(false);
                    })
                }} />
              </>
            ) : null
          }
        </div>
      </div>
      <div className="user-section call-logs">
        <div className="form-errors">
          <p className="error">Example error</p>
          <p className="error">Example error</p>
        </div>
        <h3>Prospect Details</h3>
        <div className="user-divider"></div>
        <div className="inputs">
          <div className="input-block">
            <label>Status</label>
            <br/>
            <select className="input-error">
              <option>Unqualified</option>
              <option>Qualified</option>
              <option>Dealer Qualified</option>
              <option>Converted</option>
              <option>Failed</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Sourcer</label>
            <br/>
            <select defaultValue={prospectData.sourcer} value={prospectData.sourcer} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  sourcer: e.target.value});
            }}>
              <option value="Unassigned">Unassigned</option>
              <option value="Brad">Brad</option>
              <option value="Adam">Adam</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Sales Agent</label>
            <br/>
            <select defaultValue={prospectData.sales_agent} value={prospectData.sales_agent} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  sales_agent: e.target.value});
            }}>
              <option value="Unassigned">Unassigned</option>
              <option value="Brad">Brad</option>
              <option value="Adam">Adam</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Temperature</label>
            <br/>
            <select defaultValue={prospectData.temperature} value={prospectData.temperature} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  temperature: e.target.value});
            }}>
              <option value="hot">Hot (1)</option>
              <option value="warm">Warm (2)</option>
              <option value="cold">Cold (3)</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>PCARMARKET Has Title</label>
            <br/>
            <select defaultValue={prospectData.has_title} value={prospectData.has_title} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  has_title: e.target.value});
            }}>
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Title Received in Lead</label>
            <br/>
            <select defaultValue={prospectData.title_received} value={prospectData.title_received} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  title_received: e.target.value});
            }}>
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Permission to Charge Card</label>
            <br/>
            <select defaultValue={prospectData.card_permission} value={prospectData.card_permission} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  card_permission: e.target.value});
            }}>
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Last 4 Digits of Card</label>
            <br/>
            <input defaultValue={prospectData.card_digits} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  card_digits: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
      </div>
      <div className="user-section">
        <h3>Car Details</h3>
        <div className="user-divider"></div>
        <div className="inputs">
          <div className="input-block">
            <label>Title</label>
            <br/>
            <input defaultValue={prospectData.title} required type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  title: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Primary Contact</label>
            <br/>
            <input defaultValue={prospectData.primary_contact} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  primary_contact: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Link</label>
            <br/>
            <input defaultValue={prospectData.link} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  link: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Notes</label>
            <br/>
            <input defaultValue={prospectData.notes} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  notes: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>Make</label>
            <br/>
            <input defaultValue={prospectData.make} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  make: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Model</label>
            <br/>
            <input defaultValue={prospectData.model} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  model: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Exterior color</label>
            <br/>
            <input defaultValue={prospectData.exterior_color} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  exterior_color: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Interior color</label>
            <br/>
            <input defaultValue={prospectData.interior_color} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  interior_color: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>Options</label>
            <br/>
            <input defaultValue={prospectData.options} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  options: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Type</label>
            <br/>
            <input defaultValue={prospectData.type} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  type: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Mileage</label>
            <br/>
            <input defaultValue={prospectData.mileage} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  mileage: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>VIN</label>
            <br/>
            <input defaultValue={prospectData.vin} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  vin: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>Year</label>
            <br/>
            <input defaultValue={prospectData.year} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  year: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Country</label>
            <br/>
            <input defaultValue={prospectData.country} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  country: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Postal code</label>
            <br/>
            <input defaultValue={prospectData.postal_code} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  postal_code: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Phone number</label>
            <br/>
            <input defaultValue={prospectData.phone_number} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  phone_number: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
        <div className="inputs">
          <div className="input-block">
            <label>Odometer value</label>
            <br/>
            <input defaultValue={prospectData.odometer_value} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  odometer_value: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Odometer units</label>
            <br/>
            <select defaultValue={prospectData.odometer_units} value={prospectData.odometer_units} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  odometer_units: e.target.value});
            }}>
              <option value="Miles">Miles</option>
              <option value="Kilometers">Kilometers</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Reserve auction</label>
            <br/>
            <select defaultValue={prospectData.reserve_auction} value={prospectData.reserve_auction} className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  reserve_auction: e.target.value});
            }}>
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
          <div className="input-block">
            <label>Reserve price</label>
            <br/>
            <input defaultValue={prospectData.reserve_price} type="text" className="input-error" onChange={(e) => {
              setProspectData({...prospectData,  reserve_price: e.target.value});
            }} />
            <p className="error">Example error</p>
            <p className="error">Example error</p>
          </div>
        </div>
      </div>
      <div
        className="user-section images"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <h3>Images</h3>
        <p>Drop images here to upload</p>
        <br />
        {prospectData.images.map((image, index) => (
          <div key={index} style={{ position: 'relative' }} className="image-outer">
            <img
              src={typeof image === 'string' ? image : URL.createObjectURL(image)}
              draggable={false}
            />
            <div
              onClick={() => handleImageClick(index)}
            >
              <i className="fa fa-times"></i>
            </div>
          </div>
        ))}
        <p className="error">Example error</p>
        <p className="error">Example error</p>
      </div>
      <div className="user-section call-logs">
        <h3>Contact Logs</h3>
        <br />
        <div className="new-contact">
          <input type="text" placeholder="Add new contact log" className="input-error" value={currentLog} onChange={(e) => { setCurrentLog(e.target.value) }} />
          <button onClick={(e) => {
            e.preventDefault();
            if(!currentLog.length) return;
            let currentLogs = prospectData.contact_logs;
            currentLogs.push({
              timestamp: Date.now(),
              text: currentLog
            })
            setProspectData({...prospectData,  contact_logs: currentLogs});
            setCurrentLog('');
            console.log(currentLogs);
          }}>Add</button>
        </div>
        <p className="error">Example error</p>
        <p className="error">Example error</p>
        <div className="user-divider"></div>
        {
          prospectData.contact_logs.map((log) => {
            const dateObject = new Date(log.timestamp);
            const humanDateFormat = dateObject.toLocaleString();
            return (
              <p key={log.timestamp}><strong>{humanDateFormat}</strong> {log.text}</p>
            )
          })
        }
      </div>
      {
        currentProspect && prospectData.phone_number ? (
          <div className="user-section duplicates call-logs">
            <h3>Duplicates</h3>
            <br />
            <p>Other prospects with the number <em>{prospectData.phone_number.replace(/\D+/g, '')}</em></p>
            <div className="user-divider"></div>
            {
              prospects.length && prospects.map((p) => {
                if (p.phone_number.replace(/\D+/g, '') === prospectData.phone_number.replace(/\D+/g, '')) {
                  if (p.id === prospectData.id) return;
                  if (!prospectData.phone_number.length) return;
                  return (
                    <p key={Math.random()} className="p-duplicate" onClick={() => {
                      setCurrentProspect(p.id);
                      window.scrollTo(0, 0);
                    }}>{p.title} <i className="fa fa-external-link"></i></p>
                  )
                }
              })
            }
          </div>
        ) : null
      }
    </form>
  )
}

export default NewCar;