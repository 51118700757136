import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: import any SDKs
// https://firebase.google.com/docs/web/setup#available-libraries

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: "pcarmarket-console.firebaseapp.com",
  projectId: "pcarmarket-console",
  storageBucket: "pcarmarket-console.appspot.com",
  messagingSenderId: "358264937981",
  appId: "1:358264937981:web:d951c494e26adfb7fd8d94"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// export Firebase so it can be used elsewhere
const Firebase = getFirestore(app);
export default Firebase;