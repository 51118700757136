import { useEffect, useState } from "react";

const Users = ({ setUser, setCurrentUser }) => {

  const [search, setSearch] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [dealerOnly, setDealerOnly] = useState(false);
  const [users, setUsers] = useState([]);
  const [userAmount, setUserAmount] = useState(500);

  useEffect(() => {
    if (search) {
      fetch(`https://www.pcarmarket.com/api/users/search/?api_key=${process.env.REACT_APP_API_KEY}&start=0&end=${userAmount.toString()}&order_by=username&search=${searchVal}`, {
        method: 'GET',
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          const filteredData = data.users.filter(user => !user.email.includes('@codexterous.com'));
          setUsers(filteredData);
        })
        .catch((e) => {
          console.error(e);
        })
    } else {
      fetch(`https://www.pcarmarket.com/api/users/list/?api_key=${process.env.REACT_APP_API_KEY}&start=0&end=${userAmount.toString()}&order_by=username`, {
        method: 'GET',
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          const filteredData = data.users.filter(user => !user.email.includes('@codexterous.com'));
          setUsers(filteredData);
        })
        .catch((e) => {
          console.error(e);
        })
    }
  }, [userAmount])

  const Search = (val) => {
    /**
     * Remove spaces if number search
     * @param str
     * @returns {*}
     */
    const removeSpaces = (str) => {
      if (/^[\d(+]/.test(str)) {
        return str.replace(/\s+/g, '');
      }
      return str;
    }
    val = removeSpaces(val);
    /**
     * Search
     */
    setSearchVal(val);
    if (val && val.length >= 1) {
      setSearch(true);
      fetch(`https://www.pcarmarket.com/api/users/search/?api_key=${process.env.REACT_APP_API_KEY}&start=0&end=500&order_by=username&search=${val}`, {
        method: 'GET',
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          const filteredData = data.users.filter(user => !user.email.includes('@codexterous.com'));
          setUsers(filteredData);
        })
        .catch((e) => {
          console.error(e);
        })
    } else {
      setSearch(false);
      fetch(`https://www.pcarmarket.com/api/users/list/?api_key=${process.env.REACT_APP_API_KEY}&start=0&end=${userAmount.toString()}&order_by=username`, {
        method: 'GET',
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          const filteredData = data.users.filter(user => !user.email.includes('@codexterous.com'));
          setUsers(filteredData);
        })
        .catch((e) => {
          console.error(e);
        })
    }
  }

  useEffect(() => {
    fetch(`https://www.pcarmarket.com/api/users/list/?api_key=${process.env.REACT_APP_API_KEY}&start=0&end=500&order_by=username`, {
      method: 'GET',
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        const filteredData = data.users.filter(user => !user.email.includes('@codexterous.com'));
        setUsers(filteredData);
      })
      .catch((e) => {
        console.error(e);
      })
  }, []);

  /**
   * TODO: max users per page or something?
   */

  return (
    <div className="fade">
      <div className="customers-header">
        <h2><strong>PCARMARKET</strong> User List</h2>
        <div className="customers-filters">
          {/*<div className="checkbox-block" onClick={() => setDealerOnly(!dealerOnly)}>*/}
          {/*  <div className={`checkbox ${dealerOnly ? 'checked' : ''}`}>*/}
          {/*    <i className="fa fa-check" />*/}
          {/*  </div>*/}
          {/*  <label>Dealers only</label>*/}
          {/*</div>*/}
          <input type="text" placeholder="Search users" onChange={(e) => Search(e.target.value)} />
        </div>
      </div>
      <br />
      <div className="customers">
        <div className="customer-labels">
          <div className="c-cat email">
            <p>Username</p>
          </div>
          <div className="c-cat email">
            <p>Email</p>
          </div>
          <div className="c-cat email">
            <p>First Name</p>
          </div>
          <div className="c-cat email">
            <p>Last Name</p>
          </div>
        </div>
        {
          users && users.length ? users.map((c) => {
            // if (search) {
            //   if (!c.username.includes(searchVal)
            //     && !c.first_name.includes(searchVal)
            //     && !c.last_name.includes(searchVal)
            //     && !c.email.includes(searchVal))
            //     return null;
            // }
            return (
              <div className="customer" onClick={() => {
                setUser(true);
                setCurrentUser(c.username)
              }}>
                <div className="c-cat email">
                  <p>{c.username}</p>
                </div>
                <div className="c-cat email">
                  <p>{c.email}</p>
                </div>
                <div className="c-cat email">
                  <p>{c.first_name}</p>
                </div>
                <div className="c-cat email">
                  <p>{c.last_name}</p>
                </div>
              </div>
            )
          }) : null
        }
        <button className="load-more" onClick={() => setUserAmount(userAmount + 500)}>Load more users</button>
      </div>
    </div>
  )
}

export default Users;