import Firebase from './Firebase';
import { addDoc, collection } from "firebase/firestore";
import updateFirebase from "./updateFirebase";

/**
 * Save to Firebase
 * @param location
 * @param data
 */
const saveToFirebase = async (location, data) => {
  try {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.images = [];
    const docRef = await addDoc(collection(Firebase, location), dataCopy);
    await updateFirebase(location, { id: docRef.id, timestamp: Date.now(), images: data.images }, docRef.id)
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export default saveToFirebase;